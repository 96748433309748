import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select, { components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import DetectableOverflow from 'react-detectable-overflow'

import SelectorStyles from '../../constants/selectorStyles'
import { IntentType, NlpRequestType } from 'models/IntentsType'
import { OptionItemType } from 'models/MessageTypes'
import classes from 'uiKit/AtomSelect/styles.module.scss'
import IntentModal from '../IntentModal'

interface Props {
  intents: IntentType[]
  value: OptionItemType
  onChange: (v: OptionItemType) => void
  defaultValue?: any
  isLarge?: boolean
  isCreateNewOption: boolean
  trainUserPhrase: NlpRequestType
  callback: any
}

export const IntentSelect: React.FC<Props> = ({
  intents,
  value,
  onChange,
  defaultValue,
  isCreateNewOption,
  trainUserPhrase,
  callback,
}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [isOverflowed, setIsOverflowed]: [boolean, any] = useState(false)
  const containerEndRef = useRef(null)

  const selectedValue = useMemo(
    () => options?.find(option => option?.value === value?.value || option?.label === value?.label),
    [value, options],
  )

  useEffect(() => {
    const mappedIntents = intents?.map(intent => ({ value: intent.id, label: intent.name }))

    if (defaultValue) {
      mappedIntents.unshift(defaultValue)
    }

    if (isCreateNewOption) {
      mappedIntents.unshift({ label: '+ Create new', value: 'CREATE_NEW' })
    }

    setOptions(mappedIntents)
  }, [intents])

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <DetectableOverflow onChange={handleOverflow}>{props.children}</DetectableOverflow>
      </components.SingleValue>
    )
  }

  const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div ref={containerEndRef} />
        {props.children}
      </components.MenuList>
    )
  }

  const handleOverflow = isOverflowed => {
    setIsOverflowed(isOverflowed)
  }

  const handleChange = value => {
    if (value.value === 'CREATE_NEW') {
      setOpen(true)
    } else {
      setIsOverflowed(false)
      onChange(value)
    }
  }

  return (
    <>
      <span data-tip={selectedValue?.label} data-for={selectedValue?.label}>
        <Select
          styles={SelectorStyles}
          options={options}
          value={selectedValue}
          onChange={handleChange}
          components={{ SingleValue, MenuList }}
          onMenuOpen={() => {
            if (isCreateNewOption) {
              setTimeout(() => {
                containerEndRef?.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' })
              }, 0)
            }
          }}
        />
        {isOverflowed && selectedValue?.label && (
          <ReactTooltip
            className={classes.tooltip}
            effect="solid"
            place="left"
            type="dark"
            scrollHide={true}
            id={selectedValue?.label}
          />
        )}
      </span>
      <IntentModal
        open={open}
        intents={intents}
        modalIntentId={null}
        isFaqModal={true}
        onClose={() => setOpen(false)}
        onSelect={handleChange}
        trainUserPhrase={trainUserPhrase}
        callback={callback}
      />
    </>
  )
}
