import styled from 'styled-components'
import { HAWKES_BLUE, SELAGO_GRAY } from '../../../../../constants/colors'

const SourcesTable = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const HeaderWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #EBEBEF;
  height: 64px;
  align-items: center;
  gap: 40px;
  padding: 0 24px;
`

const Header = styled.div`
  font-size: 14px;
  color: #6D7686;
`

const Button = styled.button`
  background: #1658F3;
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
`

const Row = styled.div<{ index: number }>`
  display: flex;
  font-size: 14px;
  color: #3A3F62;
  min-height: 64px;
  max-height: 300px;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  border-radius: 8px;
  padding: 24px;
  gap: 80px;
  width: 100%;
  background-color: ${({ index }) => (index % 2 != 0 ? HAWKES_BLUE : 'none')};
`

const Source = styled.div`
  ${({sourceType}) => sourceType !== 'TEXT' && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({sourceType}) => sourceType === 'TEXT' && `
    height: 300px;
    white-space: break-spaces;
  `}
`

const Delete = styled.div`
  cursor: pointer;
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: calc(100% - 60px);
  bottom: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

const DeleteWrap = styled.div`
  display: flex;
  gap: 32px;
  position: sticky;
  top: 0;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
`

const Count = styled.div`
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: #D4E1FF;
  color: #1658F3;
`

export {SourcesTable, HeaderWrap, Header, Button, Row, Source, Delete, DisableScreen, DeleteWrap, Count}
