import styled from 'styled-components'
import { FIORD_BLUE, SELAGO_GRAY } from 'constants/colors'

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  margin-top: 32px;
  margin-bottom: 16px;
  position: relative;
`

const Title = styled.p`
  margin: 0;
  color: ${FIORD_BLUE};
  font-size: 14px;
  font-weight: 700;
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`

const Button = styled.button`
  background: #1658F3;
  width: 94px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  &:disabled {
    background: #ACB0C0;
  }
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

const Textarea = styled.textarea<{ isError: boolean }>`
  position: relative;
  height: 176px;
  font-size: 14px;
  border-color: ${({ isError }) => (isError && '#FF624C')};
`

const Error = styled.div`
  font-size: 12px;
  position: absolute;
  color: #FF624C;
  bottom: 44px;
  left: 0;
`

export { Container, Title, Form, DisableScreen, Button, Textarea, Error }
