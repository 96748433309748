import React, { useState } from 'react'

import Heading from 'uiKit/texts/Heading'
import RadioButtonGroup from './RadioButtonGroup'
import WebsiteTab from './WebsiteTab'
import TextTab from './TextTab'
import FileTab from './FileTab'

import * as S from './KnowledgeBase.style'

const KnowledgeBase = () => {
  const [activeTab, setActiveTab] = useState('WEBSITE')

  return (
    <S.Container>
      <Heading>Knowledge base</Heading>
      <S.SubHeader>Connect different data sources for the chatbot knowledgebase</S.SubHeader>
      <RadioButtonGroup value={activeTab} onChange={setActiveTab} />
      {activeTab === 'WEBSITE' && <WebsiteTab/>}
      {activeTab === 'FILE' && <FileTab/>}
      {activeTab === 'TEXT' && <TextTab/>}
    </S.Container>
  )
}

export default KnowledgeBase
