import api from 'services/api'
import { logoutOnRequestOrResponseJson, customResponseParser } from 'api'
import { BASE_URL } from 'configs'

export const getKnowledgeSources = (botId: number, type: string) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge?type=${type}`)
}

export const getTrainingStatus = (botId: number) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/status`)
}

export const updateWebsiteKnowledgeSources = (botId: number, body) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload/website`, body)
}

export const updateFileKnowledgeSources = (botId: number, body, signal) => {
  return fetch(`${BASE_URL}/bot/${botId}/knowledge/upload/file`, {
    credentials: 'include',
    method: 'POST',
    body: body,
    signal: signal
  }).then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response))
}

export const updateTextKnowledgeSources = (botId: number, body) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload/text`, body)
}

export const deleteKnowledgeSources = (botId: number, id: number) => {
  return api.delete(`${BASE_URL}/bot/${botId}/knowledge?source_id=${id}`)
}

export const trainKnowledgeSources = (botId: number, body) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/train`, body)
}
