import React, { useEffect, useState } from 'react'

import * as S from './TextTab.style'
import TextForm from '../TextForm'
import { PENDING } from '../../../../settings/constants/trainingStatus'
import {
  deleteKnowledgeSources,
  getKnowledgeSources, trainKnowledgeSources,
  updateTextKnowledgeSources,
} from '../../../../settings/api/websiteKnowledgeBase'
import { alertSuccess } from 'api'
import SourcesTable from '../SourcesTable'

export const TextTab = ({ activeBot }) => {
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(PENDING)

  useEffect(() => {
    getKnowledgeSources(activeBot.id, 'TEXT').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === PENDING) ? PENDING : 'READY'
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
  }, [])

  const handleAdd = text => {
    const body = {
      sources: [text],
    }
    updateTextKnowledgeSources(activeBot.id, body).then((res) => {
      setSources(res?.sources || [])
    }).then(() => alertSuccess('Text is added successfully'))
  }

  const handleDelete = source => {
    const source_id = source._id
    return deleteKnowledgeSources(activeBot.id, source_id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source_id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('Text is deleted successfully'))
  }

  const handleTrain = () => {
    const body = {
      sources: sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id),
    }
    trainKnowledgeSources(activeBot.id, body).then(() => setTrainingStatus(PENDING))
  }

  return (
    <S.TextTab>
      <TextForm onClick={handleAdd} trainingStatus={trainingStatus}/>
      <SourcesTable sources={sources} onDelete={handleDelete} onTrain={handleTrain} trainingStatus={trainingStatus} />
    </S.TextTab>
  )
}
