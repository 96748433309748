import React, { useState } from 'react'
import DetectableOverflow from 'react-detectable-overflow'
import ReactTooltip from 'react-tooltip'

export const DetectOverflow = ({ children }) => {
  const [isOverflow, setOverflow] = useState(false)

  return (
    <>
      <span data-tip data-for={isOverflow && children}>
        <DetectableOverflow onChange={setOverflow}>{children}</DetectableOverflow>
      </span>
      {isOverflow && (
        <ReactTooltip place="top" effect="solid" id={children}>
          {children}
        </ReactTooltip>
      )}
    </>
  )
}
