import React, { useEffect, useMemo, useState } from 'react'

import WebsiteURLForm from '../WebsiteURLForm'
import SourcesTable from '../SourcesTable'
import {
  deleteKnowledgeSources,
  getKnowledgeSources,
  trainKnowledgeSources,
  updateWebsiteKnowledgeSources,
} from '../../../../settings/api/websiteKnowledgeBase'
import { alertSuccess } from 'api'

import * as S from './WebsiteTab.style'
import { PENDING } from '../../../../settings/constants/trainingStatus'
import normalizeUrl from 'normalize-url'

export const WebsiteTab = ({ activeBot }) => {
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(PENDING)
  const urls = useMemo(() => sources.map(x => x.source), [sources])

  useEffect(() => {
    getKnowledgeSources(activeBot.id, 'WEBSITE').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === PENDING) ? PENDING : 'READY'
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
  }, [])

  const handleAdd = url => {
    url = normalizeUrl(url, {forceHttps: true, stripWWW: true, removeTrailingSlash: true})
    const body = {
      sources: [url],
    }
    updateWebsiteKnowledgeSources(activeBot.id, body)
      .then(res => {
        setSources(res?.sources || [])
      })
      .then(() => alertSuccess('Website URL is added successfully'))
  }

  const handleDelete = source => {
    const source_id = source._id
    return deleteKnowledgeSources(activeBot.id, source_id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source_id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('Website URL is deleted successfully'))
  }

  const handleTrain = () => {
    const body = {
      sources: sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id),
    }
    trainKnowledgeSources(activeBot.id, body).then(() => setTrainingStatus(PENDING))
  }

  return (
    <S.WebsiteTab>
      <WebsiteURLForm onClick={handleAdd} urls={urls} trainingStatus={trainingStatus} />
      <SourcesTable sources={sources} onDelete={handleDelete} onTrain={handleTrain} trainingStatus={trainingStatus} />
    </S.WebsiteTab>
  )
}
