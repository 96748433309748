import React, { useMemo, useState } from 'react'

import { DeleteIcon } from 'uiKit/icons/DeleteIcon'

import * as S from './SourcesTable.style'
import { PENDING } from '../../../../settings/constants/trainingStatus'
import TrainingStatus from '../TrainingStatus'
import FailedStatus from '../FailedStatus'
import UploadedStatus from '../UploadedStatus'
import Tooltip from '../../../../../uiKit/Tooltip'
import LoaderOverlay from '../../../../../uiKit/loaders/loaderOverlay'
import { DetectOverflow } from '../DetectOverflow/DetectOverflow'

export const SourcesTable = ({ sources, onDelete, onTrain, trainingStatus }) => {
  const [loading, setLoading] = useState(false)
  const sourcesPending = useMemo(
    () => sources.filter(x => ['PENDING', 'FAILED', 'NOT_TRAINED'].includes(x.status)),
    [sources],
  )
  const sourcesTrained = useMemo(() => sources.filter(x => x.status === 'READY'), [sources])
  const sourcesFailed = useMemo(() => sources.some(x => x.status === 'FAILED'), [sources])

  const getSourceName = source => {
    return source.type === 'FILE' ? source.source?.split('/').pop() : source.source || source.original_text
  }

  const handleDelete = source => {
    if (source.isUploaded === false) {
      onDelete(source)
      return
    }
    setLoading(true)
    onDelete(source).finally(() => setLoading(false))
  }

  return (
    <>
      <S.SourcesTable>
        {!!sourcesPending?.length && (
          <>
            <S.HeaderWrap>
              <S.Header>TO TRAIN</S.Header>
              {trainingStatus === PENDING ? (
                <TrainingStatus />
              ) : (
                <S.Button onClick={onTrain}>{sourcesFailed ? 'Train again' : 'Start training'}</S.Button>
              )}
            </S.HeaderWrap>
            {sourcesPending.map((source, index) => (
              <S.Row key={source.source} index={index}>
                <div style={{ flex: '1 1 100%', minWidth: 0, display: 'grid' }}>
                  <S.Source sourceType={source.type}>
                    {source.type === 'TEXT' ?
                      getSourceName(source) :
                      (<DetectOverflow>{getSourceName(source)}</DetectOverflow>)}
                  </S.Source>
                </div>
                <S.DeleteWrap>
                  {source.type === 'FILE' && source.status !== 'FAILED' && (
                    <UploadedStatus isUploaded={source.isUploaded} />
                  )}
                  {source.status === 'FAILED' && <FailedStatus />}
                  <S.Delete onClick={() => handleDelete(source)}>
                    <DeleteIcon />
                  </S.Delete>
                </S.DeleteWrap>
              </S.Row>
            ))}
          </>
        )}
        {!!sourcesTrained?.length && (
          <>
            <S.HeaderWrap>
              <S.Header>TRAINED</S.Header>
            </S.HeaderWrap>
            {sourcesTrained.map((source, index) => (
              <S.Row key={source.source} index={index}>
                <div style={{ flex: '1 1 100%', minWidth: 0, display: 'grid' }}>
                  <S.Source sourceType={source.type}>
                    {source.type === 'TEXT' ?
                      getSourceName(source) :
                      (<DetectOverflow>{getSourceName(source)}</DetectOverflow>)}
                  </S.Source>
                </div>
                <S.DeleteWrap>
                  {source.count && (
                    <S.Count data-for={source.source} data-tip>
                      {source.count}
                    </S.Count>
                  )}
                  <Tooltip id={source.source}>Amount of trained pages</Tooltip>
                  <S.Delete onClick={() => handleDelete(source)}>
                    <DeleteIcon />
                  </S.Delete>
                </S.DeleteWrap>
              </S.Row>
            ))}
          </>
        )}
        {trainingStatus === PENDING && <S.DisableScreen />}
      </S.SourcesTable>
      {loading && <LoaderOverlay showLoader={loading} />}
    </>
  )
}
