import React, { useState } from 'react'

import * as S from './TextForm.style'
import { PENDING } from '../../../../settings/constants/trainingStatus'

interface Props {
  trainingStatus: string
  onClick: (url: string) => void
}

export const TextForm: React.FC<Props> = ({ onClick, trainingStatus }) => {
  const [text, setText] = useState('')
  const [error, setError] = useState('')

  const handleChange = e => {
    const isValidLength = e.target.value.length > 6000

    if (isValidLength) {
      setError("You've reached the max length for the text value — 6000 characters")
    } else {
      setError('')
    }

    setText(e.target.value)
  }

  const handleClick = () => {
    if (!error && text) {
      onClick(text)
      setText('')
    }
  }

  const handleEnter = e => {
    if (e.key === 'Enter') {
      handleClick()
      e.preventDefault()
    }
  }

  return (
    <S.Container>
      <S.Title>Insert text</S.Title>
      <S.Form>
        <S.Textarea
          placeholder="+ Insert text here"
          value={text}
          onChange={handleChange}
          onKeyPress={handleEnter}
          isError={error}
        />
        <S.Error>{error}</S.Error>
        <S.Button onClick={handleClick} disabled={!text || error}>
          Add text
        </S.Button>
      </S.Form>
      {trainingStatus === PENDING && <S.DisableScreen />}
    </S.Container>
  )
}
